import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { fallDown as BurgerMenu } from "react-burger-menu";


const Head = styled.div`
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: white;
`;

const HeaderContentWrapper = styled.div`
  max-width: 1200px;
  margin: auto;
  display: -ms-grid;
  display: grid;
  grid-template-columns: auto 1fr auto;
  -ms-grid-columns: auto 1fr auto;
  -ms-grid-rows: 80px;
  align-items: center;
  padding: 12px;
  
  div {
    -ms-grid-row: 1;
    -ms-grid-column-span: 1;
  }
  @media (min-width: 1024px) {
    padding: 8px;

    a {
      font-size: 16px;
    }
  }
`;

const HumDiv = styled.div`
  -ms-grid-column: 5;
  padding: 0 16px 0 0;
  align-self: center;
  justify-self: end;
  font-size: 12px;
  font-weight: bold;
  a {
    text-decoration: none;
    color: black;
    text-align: center;
    padding: 0 4px;
  }

  @media (min-width: 700px) {
    display: none;
  }
  
  /* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 24px;
  top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: rgba(0, 0, 0, 0.6);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 32px !important;
  width: 32px !important;
  right: 16px !important;
  top: 16px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: black;
  height: 24px !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  padding: 3.5em 1.5em 0;
  font-size: 1.15em;
  color: black;
  background: white;
  font-weight: bold;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  font-size: 1.5em;
  padding: 1em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
`;

const Menu = styled.div`
  -ms-grid-column: 5;
  margin-top: 16px;
  font-size: 14px;
  color: black;
  font-weight: bold;
  justify-self: end;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(4, 80px);
  -ms-grid-columns: 80px 80px 80px 80px;
  align-items: center;
  justify-items: stretch;
  padding-right: 16px;
  a {
    display: block;
    text-decoration: none;
    color: black;
    text-align: center;
    -ms-grid-row: 1;
    -ms-grid-column-span: 1;
  }
  a:nth-child(1) {
    -ms-grid-column: 1;
  }
  a:nth-child(2) {
    -ms-grid-column: 2;
  }
  a:nth-child(3) {
    -ms-grid-column: 3;
  }
  a:nth-child(4) {
    -ms-grid-column: 4;
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

const Logo = styled.div`
  -ms-grid-column: 1;
  padding: 0 16px;
  margin: 0;
  display: block;
  justify-items: start;
  a {
    padding: 0;
    font-size: 15px;
    text-decoration: none;
    letter-spacing: 1px;
    color: black;
    margin: 0;
    font-family: "arial black", "Arial-BoldMT", "Arial Rounded MT Bold", serif;

    @media (max-width: 700px) {
      img {
        width: 60%;
      }
    }

    @media (min-width: 1024px) {
      font-size: 22px;
    }

    /* &:hover {
      color: lightblue;
    } */
  }

  p,
  h1 {
    font-size: 10px;
    margin: 0;
  }
`;

const Header = ({ siteTitle }) => (
  <Head>
    <HeaderContentWrapper>
      <Logo>
        <Link to="/">
          <img src={require("../images/splashmedialogo (002).png")} alt="splash media"/>
        </Link>
      </Logo>
      <Menu>
        <Link to="/work">ワーク</Link>
        <Link to="/life">ライフ</Link>
        <Link to="/series">連載</Link>
        {/*<a href="https://www.instagram.com/splashmedia.jp/" target="_blank" rel="noopener noreferrer">*/}
        {/*  <img*/}
        {/*    src={require("../images/Insta46.jpg")}*/}
        {/*    alt="insta"*/}
        {/*    style={{*/}
        {/*      width: "36px",*/}
        {/*      height: "36px",*/}
        {/*      padding: "0",*/}
        {/*      margin: "0",*/}
        {/*      border: "0"*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</a>*/}
      </Menu>
      <HumDiv>
        <BurgerMenu right width={ "100%" } disableAutoFocus>
          <Link to="/work" className="menu-item">ワーク</Link>
          <Link to="/life" className="menu-item">ライフ</Link>
          <Link to="/series" className="menu-item">連載</Link>
          {/*<a href="https://www.instagram.com/splashmedia.jp/" target="_blank" rel="noopener noreferrer">*/}
          {/*  <img*/}
          {/*    src={require("../images/Insta46.jpg")}*/}
          {/*    alt="insta"*/}
          {/*    style={{*/}
          {/*      width: "36px",*/}
          {/*      height: "36px",*/}
          {/*      padding: "0",*/}
          {/*      margin: "0",*/}
          {/*      border: "0"*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</a>*/}
        </BurgerMenu>
      </HumDiv>
    </HeaderContentWrapper>
  </Head>
  // </div>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
