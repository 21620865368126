import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

const FooterText = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
  background-color: #e9e9ea;
  justify-items: center;

  a {
    color: black;
    text-decoration: none;
    font-size: 14px;
  }

  @media (max-width: 500px) {
    grid-template-columns: auto;
    justify-items: center;
  }
`;

const TubeBox = styled.div`
  display: grid;
  margin: 0 auto 24px auto;
  padding: 0 0 16px 0;
  background-color: #e9e9ea;
  justify-items: center;
  max-width: 400px;
  width: 100%;
  height: 240px;
`;

const LogoBox = styled.div`
  display: grid;
  margin: 0 auto;
  padding: 40px 0 0 0;
  background-color: #e9e9ea;
  justify-items: center;
  max-width: 250px;
`;

const FooterContainer = styled.footer`
  margin-top: 50px;
  background-color: #e9e9ea;
  width: 100%;
  padding: 50px 40px 40px 40px;
`

const Footer = () => (
  <FooterContainer>
    <TubeBox>
      <iframe
        width="100%"
        height="100%"
        title="video"
        src="https://www.youtube.com/embed/5SYwYGietx0"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </TubeBox>

    <FooterText>
      <Link to="/aboutus/">About Us</Link>
      <Link to="/company/">運営会社</Link>
      <Link to="/press/">リリース受付・取材依頼</Link>
      <Link to="/inquiry/">お問い合わせ</Link>
      {/*<a href="mailto:info-splashmedia@splashtop.co.jp">お問い合わせ</a>*/}
      {/* <Link to="/">プライバシーポリシー</Link> */}
    </FooterText>
    <LogoBox>
      <a href="https://www.splashtop.co.jp/" target="_blank" rel="noopener noreferrer">
        <img src={require("../images/footerlogo.png")} alt="" />
      </a>
    </LogoBox>
  </FooterContainer>
);

export default Footer;
