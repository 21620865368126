import React from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik'

import styled from "styled-components";
import axios from "axios"

const Container = styled.div`
  display: grid;
  row-gap: 12px;
  justify-items: center;
  margin: 16px auto;
  max-width: 790px;
  
  p {
    text-align: left;
    width: 100%;
  }
 
  .formik {
    input, textarea {
      width: 100%;
    }
    input.form-control {
      width: 100%;
      height: 30px;
      border: none;
      outline: 0;
      border-bottom: 1px solid #d1d5db;
      font-size: 90%;
    }
    textarea.form-control {
      outline: 0;
      border: 1px solid #d1d5db;
      font-size: 90%;
    }
    .underline {
      width: 100%;
      height: 1px;
      background: black;
      transform-origin: center center;
      transform: scaleX(0);
      transition: transform 0.18s ease-out;
    }
    
    input.form-control:focus + .underline {
        transform: scaleX(1);
      }
    .form-group {
      width: 100%;
      margin-bottom: 1em;
    }
    .form-group-submit {
      text-align: right;
    }
    .control-label {
      font-size: 80%;
      font-weight: bold;
    }
    .error {
      color: #CC0000;
      font-size: 65%;
      font-weight: bold;
    }
    .btn {
      padding:4px;
      background-color:black;
      color:white;
      font-size:12px;
      margin-top:8px;
      appearance:none;
      outline:0;
    }
  }
`

class FormComponent extends React.Component {
  render() {
    return (
      <Container>
        <Formik
          initialValues={{ app: this.props.appName, company: '', email: '', full_name: '', body: '' }}
          //          initialValues={{ company: '', email: '', name: '', body: '' }}
          validate={values => {
            let errors = {}
            if (!values.company) {
              errors.company = '会社名を入力してください'
            }
            if (!values.full_name) {
              errors.full_name = '氏名を入力してください'
            }
            if (!values.email) {
              errors.email = 'メールアドレスを入力してください'
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'メールアドレスを正しく入力してください'
            }
            if (!values.body) {
              errors.body = 'お問い合わせ内容を入力してください'
            }
            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            axios
              .get(process.env.GATSBY_API_URL, { params: values })
              .then(response => {
                const data = response.data
                console.log(data)
                if (data.status === 'OK') {
                  alert('メールを送信しました')
                  document.location.href = '/'
                } else {
                  alert(data.error)
                  setSubmitting(false)
                }
              }).catch(error => {
              console.error(error)
              alert(error.message)
              setSubmitting(false)
            })
            // setTimeout(() => {
            //   alert(JSON.stringify(values, null, 2))
            //   setSubmitting(false)
            // }, 400)
          }}
        >
          {({
              isSubmitting
            }) => (
            <Form className="formik">
              <Field type="hidden" name="app"/>
              <div className="form-group">
                <label htmlFor="company" className="control-label">会社名</label>
                <Field type="text" name="company" className="form-control"/>
                <div className="underline"/>
                <ErrorMessage name="company" component="div" className="error"/>
              </div>
              <div className="form-group">
                <label htmlFor="full_name" className="control-label">氏名</label>
                <Field type="text" name="full_name" className="form-control"/>
                <div className="underline"/>
                <ErrorMessage name="full_name" component="div" className="error"/>
              </div>
              <div className="form-group">
                <label htmlFor="email" className="control-label">メールアドレス</label>
                <Field type="email" name="email" id="email" className="form-control" />
                <div className="underline"/>
                <ErrorMessage name="email" component="div" className="error" />
              </div>
              <div className="form-group">
                <label htmlFor="body" className="control-label">お問い合わせ内容</label>
                <Field component="textarea" name="body" id="body" rows="5" className="form-control" />
                <ErrorMessage name="body" component="div" className="error" />
              </div>
              <div className="form-group form-group-submit">
                <button type="submit" disabled={isSubmitting} className="">
                  {this.props.title}
                </button>
              </div>
            </Form>

          )}
        </Formik>
      </Container>

    )
  }
}

export default FormComponent