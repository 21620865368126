import Form from "../components/formcomponent"
import Layout from "../components/layout";
import SEO from "../components/seo";
import SectionTitle from "../components/sectiontitle";
import React from "react";


const Press = () => {
  return (
    <Layout>
      <SEO title="お問い合わせ" keywords={[`働き方`, `リモートデスクトップ`, `時短`]} />
      <SectionTitle value="お問い合わせ" button="記事一覧へ" visible="off" link="/" />
      <Form appName="inquiry" title="お問い合わせ"/>
    </Layout>
  )
}

export default Press