import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components"
import { addBackToTop } from 'vanilla-back-to-top'

import Header from "./header";
import Footer from "./footer";
import "./layout.css";
require('@fortawesome/fontawesome')
require('@fortawesome/fontawesome-free-solid')
require('@fortawesome/fontawesome-free-regular')
require('@fortawesome/fontawesome-free-brands')

const GoToTopContainer = styled.div`
`

class GoToTop extends React.Component {

  componentDidMount()
  {
    addBackToTop({
      scrollDuration: 500,
      innerHTML: '<img src="'+require('../images/アンカーリンク.png')+'"/>'
    })
  }

  render () {
    return null
  }
}

const Layout = (props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div style={{ height: "120px", maxWidth: "100%" }} />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: props.maxWidth || "1200px",
            padding: "0 8px"
          }}
        >
          <main>{props.children}</main>
        </div>
        <GoToTopContainer>
          <GoToTop />
        </GoToTopContainer>
        <Footer />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
